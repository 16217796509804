import { Collapse, Offcanvas, Popover, Toast } from 'bootstrap';
import * as $ from "jquery";
import axios from 'axios';

// Create an example popover
document.querySelectorAll('[data-bs-toggle="popover"]')
  .forEach(popover => {
    new Popover(popover);
  });

// Scroll

const sectionsEls = $('[data-title]').toArray();
const titleEl = $('#title');

$(window).on('scroll', () => {
  const scrollTop = $(window).scrollTop();
  if (scrollTop > 0) {
    $('#my-navbar').addClass('menu-bg');
  } else {
    $('#my-navbar').removeClass('menu-bg');
  }

  let current = 'Greengage';
  sectionsEls.forEach(el => {
    if (el.offsetTop - scrollTop <= 0) {
      current = el.getAttribute('data-title');
    }
  });

  titleEl.text(current);
});

const navLinks = document.querySelectorAll('#navbarNav .nav-item');
navLinks.forEach((l) => {
  l.addEventListener('click', () => {
    const menuToggle = document.getElementById('navbarNav');
    const collapse = Collapse.getInstance(menuToggle);
    if (collapse != null) {
      collapse.hide();
    }
  })
});

const form = document.getElementById('contactForm') as HTMLFormElement;
form.addEventListener('submit', (event) => {
  event.preventDefault();

  form.classList.add('was-validated');

  if (form.checkValidity()) {
    const contactOffcanvasElement = document.getElementById('contactOffcanvas');
    const contactOffcanvas = Offcanvas.getInstance(contactOffcanvasElement);

    const contactSubmitElement = document.getElementById('contactSubmit') as HTMLButtonElement;

    contactSubmitElement.disabled = true;

    axios.post('https://formbold.com/s/6MPEv', new FormData(form)).then(() => {
      contactOffcanvas.hide();
      form.reset();
      contactSubmitElement.disabled = false;
      form.classList.remove('was-validated');

      const contactSuccessToast = document.getElementById('contactSuccessToast');
      const toast = new Toast(contactSuccessToast);
      toast.show();
    }).catch(() => {
      contactOffcanvas.hide();
      contactSubmitElement.disabled = true;

      const contactFailedToast = document.getElementById('contactFailedToast');
      const toast = new Toast(contactFailedToast);
      toast.show();
    });
  }
}, false);